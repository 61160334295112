import type { Data, ChartTheme, ChartThemeOption, PageThemeOption, PageTheme, ComponentThemeOption, ComponentTheme, NoteThemeOption, NoteTheme } from "@types";

export function toPercent(value: number | string, decimals: number = 0): string {
    const num = typeof value === 'string' ? parseFloat(value) : value;
    return `${(num * 100).toFixed(decimals)}%`;
}

export function sortData(data: Data[], sort: "none" | "asc" | "desc" = "none"): Data[] {
    if (sort === "none") return [...data];
    return [...data].sort((a, b) => {
        const diff = Number(a.value) - Number(b.value);
        return sort === "asc" ? diff : -diff;
    });
}

export function getChartColours(theme: ChartThemeOption, useHex: boolean = false) {
    const colours = {
        green: useHex ?
            ["#58a99f", "#3f8d85", "#30716b", "#2a5b57", "#254a48", "#223f3d"] :
            ["bg-green-400", "bg-green-500", "bg-green-600", "bg-green-700", "bg-green-800", "bg-green-900"],
        yellow: useHex ?
            ["#f4e225", "#f5bc00", "#e09200", "#bb6102", "#964608", "#7a330b"] :
            ["bg-yellow-400", "bg-yellow-500", "bg-yellow-600", "bg-yellow-700", "bg-yellow-800", "bg-yellow-900"],
        pink: useHex ?
            ["#ff585d", "#f83b41", "#e51d23", "#c11419", "#a01418", "#84181b"] :
            ["bg-pink-400", "bg-pink-500", "bg-pink-600", "bg-pink-700", "bg-pink-800", "bg-pink-900"],
        grey: useHex ?
            ["#a3a3a3", "#737373", "#525252", "#404040", "#262626", "#171717"] :
            ["bg-gray-400", "bg-gray-500", "bg-gray-600", "bg-gray-700", "bg-gray-800", "bg-gray-900"]
    };
    return colours[theme];
}

export function getChartTheme(theme: ChartThemeOption, useHex: boolean = false): ChartTheme {
    const themes = {
        green: {
            bg: useHex ? "#58a99f" : "bg-green-400",
            text: useHex ? "#223f3d" : "text-green-900",
            border: useHex ? "#3f8d85" : "border-green-500"
        },
        yellow: {
            bg: useHex ? "#f4e225" : "bg-yellow-400",
            text: useHex ? "#7a330b" : "text-yellow-900",
            border: useHex ? "#e09200" : "border-yellow-600"
        },
        pink: {
            bg: useHex ? "#ff585d" : "bg-pink-400",
            text: useHex ? "#84181b" : "text-pink-900",
            border: useHex ? "#f83b41" : "border-pink-500"
        },
        grey: {
            bg: useHex ? "#737373" : "bg-grey-500",
            text: useHex ? "#171717" : "text-grey-900",
            border: useHex ? "#525252" : "border-gray-600"
        }
    }
    return themes[theme];
}

export function getPageTheme(theme: PageThemeOption, useHex: boolean = false): PageTheme {
    const themes = {
        white: {
            bg: useHex ? "#FFFFFF" : "bg-white",
            text: useHex ? "#000000" : "text-black"
        },
        yellow: {
            bg: useHex ? "#fbfeec" : "bg-yellow-50",
            text: useHex ? "#000000" : "text-black"
        }
    }
    return themes[theme];
}

export function getComponentTheme(theme: ComponentThemeOption, useHex: boolean = false): ComponentTheme {
    const themes = {
        green: {
            bg: useHex ? "#58a99f" : "bg-green-400",
            text: useHex ? "#254a48" : "text-green-900",
            textAlt: useHex ? "#3f8d85" : "text-green-500",
            border: useHex ? "#a3a3a3" : "border-green-200"
        },
        yellow: {
            bg: useHex ? "#f5bc00" : "bg-yellow-500",
            text: useHex ? "#964608" : "text-yellow-900",
            textAlt: useHex ? "#e09200" : "text-yellow-600",
            border: useHex ? "#f4e225" : "border-yellow-400"
        },
        pink: {
            bg: useHex ? "#ff585d" : "bg-pink-400",
            text: useHex ? "#84181b" : "text-pink-900",
            textAlt: useHex ? "#f83b41" : "text-pink-500",
            border: useHex ? "#ff585d" : "border-pink-400"
        },
        grey: {
            bg: useHex ? "#737373" : "bg-gray-500",
            text: useHex ? "#171717" : "text-gray-900",
            textAlt: useHex ? "#525252" : "text-gray-600",
            border: useHex ? "#404040" : "border-gray-200"
        }
    }
    return themes[theme];
}


export function getNoteTheme(theme: NoteThemeOption, useHex: boolean = false): NoteTheme {
    const themes = {
        green: {
            bg: useHex ? "#58a99f" : "bg-green-100",
            corner: useHex ? "#58a99f" : "bg-green-400",
            text: useHex ? "#254a48" : "text-green-900",
            border: useHex ? "#a3a3a3" : "border-green-200"
        },
        yellow: {
            bg: useHex ? "#f5bc00" : "bg-yellow-100",
            corner: useHex ? "#e09200" : "bg-yellow-600",
            text: useHex ? "#964608" : "text-yellow-900",
            border: useHex ? "#f4e225" : "border-yellow-400"
        },
        pink: {
            bg: useHex ? "#ff585d" : "bg-pink-100",
            corner: useHex ? "#f83b41" : "bg-pink-500",
            text: useHex ? "#84181b" : "text-pink-900",
            border: useHex ? "#ffa0a3" : "border-pink-300"
        },
        grey: {
            bg: useHex ? "#737373" : "bg-gray-500",
            corner: useHex ? "#525252" : "bg-gray-600",
            text: useHex ? "#171717" : "text-gray-900",
            border: useHex ? "#404040" : "border-gray-200"
        }
    }
    return themes[theme];
}

export function prefersReducedMotion() {
    return window.matchMedia('(prefers-reduced-motion: reduce)').matches;
}

export function getOgImage() {
    const width = 1200;
    const height = 630;
    return `/og-${width}x${height}.png`;
}